<template>
  <div id="mobileBody">
    <div class="title">CreativeMass</div>
    <van-swipe ref="banner" @change="onChange" :loop="true" :show-indicators="false">
      <van-swipe-item v-for="banner in bannerList" :key="banner.id">
        <a :href="banner.targetUrl" target="_blank" @click="bannerClick(banner)">
          <div class="bannerImg" :style="'background-image: url(' + toMedia(banner.imgUrl) + ')'"
               @touchstart="touchStart" @touchend="touchEnd"></div>
        </a>
      </van-swipe-item>
    </van-swipe>
    <div class="indicator">
      <div>
        <div v-for="(item, index) in bannerList" :key="item.id">
          <div class="bannerTitle" v-if="index == bannerIndex">{{ item.title }}</div>
        </div>
      </div>
      <div class="bannerButton">
        <div class="bannerPrev bannerPrevSelect" v-if="bannerIndex!=0" @click="bannerPrev"></div>
        <div class="bannerPrev bannerPrevUnSelect" v-if="bannerIndex==0"></div>
        <div class="bannerNext bannerNextSelect" v-if="bannerIndex!=bannerList.length-1" @click="bannerNext"></div>
        <div class="bannerNext bannerNextUnSelect" v-if="bannerIndex==bannerList.length-1"></div>
      </div>
    </div>
    <div class="fg"></div>
    <div id="navigationType"></div>
    <van-search class="search" ref="search" v-model="searchTitle" v-if="showSearch"
                @blur="hideSearch" @input="onInput">
      <template v-slot:left-icon>
        <img src="@/img/search.svg" class="searchIcon"/>
      </template>
    </van-search>
    <!--    <van-tabs v-model="selectIndex" style="clear: both" scrollspy sticky-->
    <!--              title-active-color="rgba(52,52,52,1)" title-inactive-color="rgba(52,52,52,0.2)">-->
    <van-tabs v-model="selectIndex" style="clear: both"
              :class="[showSearch ? 'show-search' : '', hidePanel ? 'hide-panel' : '']"
              title-active-color="rgba(52,52,52,1)" title-inactive-color="rgba(52,52,52,0.2)"
               ref="typeTabs">
      <van-tab :title="navigationType.title" v-for="(navigationType, typeIndex) in navigationTypeList" :key="typeIndex"
               :name="navigationType.id">
<!--        <div class="navigationTypeTitle" v-if="typeIndex != 0">{{ navigationType.title }}</div>-->
        <div class="navigationList" v-if="navigationData[navigationType.id] != null">
<!--          <div class="navigationItem" v-for="(navigation, itemIndex) in navigationData[navigationType.id].navigationList"-->
<!--               :key="navigation.id" :class="[typeIndex != 0 || (itemIndex != 0 && itemIndex != 1)? 'otherItem' : '']">-->
          <div class="navigationItem" v-for="(navigation) in navigationData[navigationType.id].navigationList"
               :key="navigation.id">
            <div class="navigationImg">
              <a :href="navigation.targetUrl" target="_blank">
                <van-image width="100%" height="100%" fit="contain" lazy-load :src="toMedia(navigation.imgUrl)"></van-image>
              </a>
            </div>
            <div class="navigationTitle">{{ navigation.title }}</div>
            <div class="navigationSubTitle">{{ navigation.introduction }}</div>
          </div>
        </div>
      </van-tab>
      <!--      <van-tab :title="navigationType.title" v-for="(navigationType, index) in navigationTypeList" :key="index"-->
      <!--               :name="navigationType.id">-->
      <!--        <div class="navigationItem" v-for="item in navigationList" :key="item.id">-->
      <!--          <div class="navigationImg">-->
      <!--            <van-image width="100%" height="100%" fit="contain" :src="toMedia(item.imgUrl)"></van-image>-->
      <!--          </div>-->
      <!--          <div class="navigationTitle">{{ item.title }}</div>-->
      <!--          <div class="navigationTitle">{{ item.introduction }}</div>-->
      <!--        </div>-->
      <!--      </van-tab>-->
    </van-tabs>
    <div class="searchPanel" v-if="showSearchPanel">
      <div v-if="searchNavigationList == null || searchNavigationList.length == 0" class="no-data">
        暂无结果，换个词试试
      </div>
      <div class="navigationList">
<!--        <div class="navigationItem" v-for="(navigation, itemIndex) in searchNavigationList"-->
<!--             :key="navigation.id" :class="[(itemIndex != 0 && itemIndex != 1) ? 'otherItem' : '']">-->
        <div class="navigationItem" v-for="(navigation) in searchNavigationList"
             :key="navigation.id">
          <div class="navigationImg">
            <a :href="navigation.targetUrl" target="_blank">
              <van-image width="100%" height="100%" fit="contain" lazy-load :src="toMedia(navigation.imgUrl)"></van-image>
            </a>
          </div>
          <div class="navigationTitle">{{ navigation.title }}</div>
          <div class="navigationSubTitle">{{ navigation.introduction }}</div>
        </div>
      </div>
    </div>
    <div class="to-top" @click="toTop" v-show="showToTop">
      <img src="../img/to_top.svg" height="50" width="50"/>
    </div>
    <footer class="footer">
      <div class="footer-fg"></div>
      <div style="margin-top: 3.6%">
        {{ footerInfo.yearContent }} ·
        <a :href="footerInfo.icpNumberTargetUrl" target="_blank">
          {{ footerInfo.icpNumber }}
        </a>
      </div>
      <div>
        <a :href="footerInfo.policeNumberTargetUrl" target="_blank">
          {{ footerInfo.policeNumber }}
        </a> ·
        <a :href="footerInfo.aboutUsTargetUrl" target="_blank">{{ footerInfo.aboutUs }}</a>
      </div>
    </footer>
  </div>
</template>

<script>

import {toMedia} from "@/utils/request";
import {getBanner, bannerClick} from "@/api/banner";
import {getNavigaionType} from "@/api/navigation_type";
import {getAllNavigation, getNavigation} from "@/api/navigation";
import {getFooter} from "@/api/footer";

const bannerPlayTime = 3000

export default {
  name: "Mobile",
  data() {
    return {
      bannerIndex: 0,
      bannerList: [],
      selectIndex: 0,
      navigationData: {},
      navigationTypeList: [],
      showSearch: false,
      showSearchPanel: false,
      hidePanel: false,
      searchTitle: '',
      footerInfo: {},
      searchNavigationList: [],
      swipeTimer: "",
      showToTop: false
    }
  },
  watch: {
    bannerList: function () {
      this.$nextTick(() => {
        // this.htmlNavigationType = document.getElementsByClassName('van-sticky')[0]
        // document.querySelector('.van-sticky').onclick = (e) => {
        //   if (e.target.className === "van-sticky" || e.target.className === "van-sticky van-sticky--fixed") {
        //     this.showSearch = true
        //     this.$nextTick(() => {
        //       this.$refs.search.querySelector('input').focus()
        //     })
        //     this.htmlHide(this.htmlNavigationType)
        //     window.location.href = "#navigationType"
        //   }
        // }

        this.$refs.typeTabs.$refs.wrap.onclick = (e) => {
          if (e.target.className === "van-tabs__wrap" || e.target.className === "van-tabs__wrap van-tabs__wrap--scrollable") {
            this.showSearch = true
            this.$nextTick(() => {
              this.$refs.search.querySelector('input').focus()
            })
          }
        }
        setTimeout(() => {
          this.bannerNext()
        }, bannerPlayTime)
        window.addEventListener('scroll', this.windowScrollListener)
      })
    }
  },
  created() {
    getBanner().then(res => {
      this.bannerList = res.data
    })
    getNavigaionType().then(res => {
      this.navigationTypeList = res.data
    })
    getFooter().then(res => {
      this.footerInfo = res.data
    })
    if (this.navigationData != null) {
      getAllNavigation().then(res => {
        this.navigationData = res.data
      })
    }
  },
  methods: {
    touchStart() {
      clearTimeout(this.swipeTimer)
    },
    touchEnd() {
      this.swipeTimer = setTimeout(() => {
        this.$refs.banner.next()
      }, bannerPlayTime)
    },
    onChange(index) {
      this.bannerIndex = index;
      clearTimeout(this.swipeTimer)
      this.swipeTimer = setTimeout(() => {
        this.$refs.banner.next()
      }, bannerPlayTime)
    },
    toMedia(url) {
      return toMedia(url)
    },
    bannerPrev() {
      this.$refs.banner.prev()
    },
    bannerNext() {
      this.$refs.banner.next()
    },
    bannerSwipe(index) {
      this.$refs.banner.swipeTo(index)
    },
    hideSearch() {
      if (this.searchTitle == '') {
        this.showSearch = false
        this.hidePanel = false
        this.searchNavigationList = undefined
        this.showSearchPanel = false
      }
    },
    onInput(value) {
      if (value != '') {
        this.showSearchPanel = true
        this.hidePanel = true
        getNavigation({title: value}).then(res => {
          this.searchNavigationList = res.data
        })
      }
    },
    bannerClick(banner) {
      bannerClick({id: banner.id})
      window._czc && window._czc.push(['_trackEvent', '轮播图', '点击', banner.title])
    },
    toTop() {
      window.scrollTo({
        top,
        behavior: "smooth"
      })
    },
    windowScrollListener() {
      var windowY = 999
      if(!this.showSearch){
        windowY = this.$refs.typeTabs.$refs.nav.getBoundingClientRect().y
      }else{
        windowY = this.$refs.search.getBoundingClientRect().y
      }
      if (windowY >= 0) {
        this.showToTop = false
      } else {
        this.showToTop = true
      }
    }
  }
}
</script>

<style scoped>
.to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
}

/deep/ .van-field__left-icon{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.searchIcon{
  height: 33px;
}

.no-data{
  font-size: 27px;
  color: rgba(52, 52, 52, 0.3);
  margin: 75px 0 120px 0;
}

.footer-fg{
  height: 2px;
  width: 100%;
  background-color: rgba(52,52,52,0.1);
}

.otherItem{
  margin-top: 54px;
}

#mobileBody{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/deep/ .hide-panel .van-tabs__content {
  display: none;
}

/deep/ .show-search .van-tabs__wrap {
  display: none;
}

.searchPanel {
  width: 92.8%;
  margin-left: 3.6%;
  flex: 1;
}

.footer {
  height: 134px;
  width: 92.8%;
  margin-left: 3.6%;
  /*margin-top: 54px;*/
  font-size: 22px;
  font-weight: 400;
  color: rgba(52, 52, 52, 0.3);
  line-height: 40px;
}

/deep/ .van-field__left-icon {
  margin-right: 27px;
}

/deep/ .van-icon-clear:before {
  content: '';
  background-image: url("~@/img/search_clean.svg");
  background-size: 100% 100%;
  width: 25px;
  height: 25px;
  padding-right: 27px;
}

/deep/ .van-search__content {
  border: 3px solid rgba(52, 52, 52, 0.05);
  background-color: white;
  padding-left: 27px;
}

.search {
  width: 92.8%;
  height: 155px;
  margin: 0 3.6%;
  padding: 0;
  position: static !important;
}

.navigationImg {
  background-size: 100% 100%;
  width: 100%;
  aspect-ratio: 333/221;
}

.navigationSubTitle {
  font-size: 25px;
  font-weight: 400;
  color: #343434;
  text-align: left;
  /*margin-bottom: 54px;*/
  font-family: PingFangSC-Regular, PingFang SC;
  max-width: 100%;
  /*text-overflow: ellipsis;*/
  /*white-space: nowrap;*/
  /*overflow: hidden;*/
  margin-bottom: 54px;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.navigationTitle {
  font-size: 29px;
  font-weight: 600;
  color: #343434;
  text-align: left;
  margin-top: 18px;
  margin-bottom: 9px;
  max-width: 100%;
  /*text-overflow: ellipsis;*/
  /*white-space: nowrap;*/
  /*overflow: hidden;*/
  font-family: PingFangSC-Semibold, PingFang SC;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/deep/ .van-tab--active {
  font-weight: 600;
  font-family: PingFangSC-Semibold, PingFang SC;
}

.navigationList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

/deep/ .van-tabs__content {
  margin-left: 3.6%;
  width: 92.8%;
}

.navigationTypeTitle {
  font-size: 33px;
  text-align: left;
  font-weight: 600;
  color: #343434;
  /*margin-bottom: 54px;*/
  padding-top: 54px;
  font-family: PingFangSC-Semibold, PingFang SC;
}

/*粘性----------------------------------start*/
/*/deep/ .van-sticky {*/
/*  background-color: white;*/
/*}*/

/*/deep/ .van-sticky::after {*/
/*  content: "";*/
/*  width: 33px;*/
/*  height: 34px;*/
/*  position: absolute;*/
/*  background-image: url("../img/search.svg");*/
/*  background-size: 100% 100%;*/
/*  right: 3.6%;*/
/*  top: 58px;*/
/*}*/
/*粘性----------------------------------end*/

/*非粘性----------------------------------start*/
/deep/ .van-tabs__wrap::after {
  content: "";
  width: 33px;
  height: 34px;
  position: absolute;
  background-image: url("~@/img/search.svg");
  background-size: 100% 100%;
  right: 3.6%;
  top: 60px;
}

/deep/ .van-tabs__wrap::before {
  content: "";
  position: absolute;
  width: 80px;
  height: 155px;
  /*background-color: #2c3e5080;*/
  background: linear-gradient(90deg, #2c3e5000 0%, #FFFFFF 100%);
  right: 21.3%;
  z-index: 2;
  cursor: pointer;
  pointer-events: none;
}

/*非粘性----------------------------------end*/

/deep/ .van-tabs--line .van-tabs__wrap {
  height: 155px;
}

/deep/ .van-tab {
  display: unset;
  text-align: left;
  line-height: 155px;
  font-size: 33px;
  padding: 0 50px;
}

/deep/ .van-tabs__nav--line.van-tabs__nav--complete {
  padding: 0;
}

/deep/ .van-tabs__wrap--scrollable .van-tab {
  padding-left: 0px;
  padding-right: 36px;
}

.indicatorImg {
  width: 50px;
}

.navigationItem {
  width: 47.84%;
}

.van-tab__pane {
  /*display: flex;*/
  /*flex-direction: column;*/
  /*flex-wrap: wrap;*/
  /*justify-content: space-between;*/
  width: 100%;
}

.van-icon-search {
  /*float: right;*/
  position: absolute;
  right: 30px;
  top: 20px;
}

.fg {
  clear: both;
  height: 4px;
  background-color: rgba(52, 52, 52, 0.05);
  margin: 0 3.6%;
}

.unClick {
  opacity: 0.5;
}

.title {
  text-align: left;
  font-size: 72px;
  margin: 72px 3.6% 54px 3.6%;
  font-family: HarmonyOS_Sans_Black;
  color: #343434;
  /*font-family: HarmonyOS_Sans_Black;*/
}

.bannerNum {
  display: none;
}

.bannerPrevSelect {
  background-image: url("~@/img/left_select.png");
}

.bannerPrevUnSelect {
  background-image: url("~@/img/left_unselect.png");
}

.bannerNextSelect {
  background-image: url("~@/img/right_select.png");
}

.bannerNextUnSelect {
  background-image: url("~@/img/right_unselect.png");
}

.bannerPrev, .bannerNext {
  width: 33.33%;
  aspect-ratio: 27/31;
  background-size: 100% 100%;
  float: left;
}

.bannerPrev {
  margin-right: 33.33%;
}

.bannerButton {
  float: right;
  margin-right: 3.6%;
  margin-top: 7%;
  /*margin-bottom: 7%;*/
  width: 10.8%;
}

.bannerTitle {
  float: left;
  margin-left: 3.6%;
  font-weight: 600;
  color: #343434;
  font-size: 29px;
  line-height: 100%;
  margin-top: 7.27%;
  /*height: 45px;*/
  /*line-height: 45px;*/
  /*margin-top: 6%;*/
  font-family: PingFangSC-Semibold, PingFang SC;

  text-align: left;
  width: 77.33%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.bannerImg {
  width: 100%;
  aspect-ratio: 696/341;
  background-size: 100% 100%;
  /*height: 100%;*/
}

/deep/ .van-swipe, .van-swipe__track {
  width: 92.8%;
  /*width: 80%;*/
  margin-left: 3.6%;
}

.indicator {
  position: static;
  width: 100%;
  aspect-ratio: 750/136;
  /*background: rgba(0, 0, 0, 0.1);*/
}

/deep/ .van-tabs__wrap {
  width: 75%;
  margin-left: 3.6%;
}

/deep/ .van-tabs__line {
  display: none;
}
</style>
