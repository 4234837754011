<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "App",
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  },
  mounted() {
    if (this._isMobile()) {
      this.$router.replace('/mobile', () => {
      })
    } else {
      this.$router.replace('/', () => {
      })
    }
  }
}
</script>

<style>
#cnzz_stat_icon_1281264626{
  display: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

a:link {
  color: rgba(52, 52, 52, 0.3);
}

a:visited {
  color: rgba(52, 52, 52, 0.3);
}

a:hover {
  color: rgba(52, 52, 52, 0.3);
}

a:active {
  color: rgba(52, 52, 52, 0.3);
}
</style>
