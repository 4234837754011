import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.css";
import "lib-flexible";
import VueLazyload from 'vue-lazyload';
import "@/assets/font/font.css"

Vue.use(Vant);
Vue.use(VueLazyload, {
  preLoad: 1.3, //预加载的宽高比
  // loading: loadimage, //图片加载状态下显示的图片
  // error: errorimage, //图片加载失败时显示的图片
  // attempt: 1, // 加载错误后最大尝试次数
})

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
