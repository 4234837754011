<template>
  <div class="home">
    <div class="pc-title">CreativeMass</div>
    <div class="banner">
      <van-swipe ref="banner" @change="onChange" :loop="true" :show-indicators="false" :touchable="false">
        <van-swipe-item v-for="banner in bannerList" :key="banner.id">
          <a :href="banner.targetUrl" target="_blank" @click="bannerClick(banner)" draggable="false">
            <div class="bannerImg" :style="'background-image: url(' + toMedia(banner.imgUrl) + ')'"></div>
          </a>
        </van-swipe-item>
      </van-swipe>
      <div class="indicator pc-indicator">
        <div class="bannerTitleList">
          <div :class="[bannerIndex == index ? 'selected' : 'notselected']"
               v-for="(banner, index) in bannerList" :key="banner.id"
               @click="bannerSwipe(index)">
            <div class="bannerTitleItem">{{ banner.title }}</div>
            <div class="fg"></div>
          </div>
        </div>
        <div class="bannerTitleListBottom"></div>
        <div class="bannerIndicator">
          <div class="bannerPrev bannerPrevSelect" v-if="bannerIndex!=0" @click="bannerPrev"
               style="cursor:pointer;"></div>
          <div class="bannerPrev bannerPrevUnSelect" v-if="bannerIndex==0"></div>
          <div class="bannerNext bannerNextSelect" v-if="bannerIndex!=bannerList.length-1" @click="bannerNext"
               style="cursor:pointer;"></div>
          <div class="bannerNext bannerNextUnSelect" v-if="bannerIndex==bannerList.length-1"></div>
          <div class="bannerNum">
            <div>{{ bannerIndex + 1 }}/{{ bannerList.length }}</div>
          </div>
        </div>
      </div>
    </div>
    <van-search class="search" ref="search" v-model="searchTitle" v-if="showSearch"
                @blur="hideSearch" @input="onInput">
      <template v-slot:left-icon>
        <img src="@/img/search.svg" class="searchIcon" width="20" height="20"/>
      </template>
    </van-search>
    <van-tabs v-model="selectIndex" scrollspy style="clear: both" ref="navigationType"
              :class="[showSearch ? 'show-search' : '', hidePanel ? 'hide-panel' : '']"
              title-active-color="rgba(52,52,52,1)" title-inactive-color="rgba(52,52,52,0.2)">
      <van-tab :title="navigationType.title" v-for="(navigationType, typeIndex) in navigationTypeList" :key="typeIndex"
               :name="navigationType.id">
        <div class="navigationTypeTitle pc-type-item-title" v-if="typeIndex != 0">{{ navigationType.title }}</div>
        <div class="navigationList" v-if="navigationData[navigationType.id] != null">
          <div class="navigationItem"
               v-for="(navigation, itemIndex) in navigationData[navigationType.id].navigationList"
               :key="navigation.id"
               :class="[typeIndex != 0 || itemIndex >= navigationRowCount ? 'otherItem' : '',
               itemIndex % navigationRowCount == 0 ? 'row-first': '']">
            <div class="navigationImg">
              <a :href="navigation.targetUrl" target="_blank" @dragstart.prevent>
                <van-image width="100%" height="100%" fit="contain" lazy-load
                           :src="toMedia(navigation.imgUrl)"></van-image>
              </a>
            </div>
            <div class="navigationTitle pc-item-title">{{ navigation.title }}</div>
            <div class="navigationSubTitle pc-item-sub-title">{{ navigation.introduction }}</div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <div class="searchPanel" v-if="showSearchPanel">
      <div class="no-data" v-if="searchNavigationList == undefined || searchNavigationList.length == 0">
        暂无结果，换个词试试
      </div>
      <div class="navigationList">
        <div class="navigationItem" v-for="(navigation, itemIndex) in searchNavigationList"
             :key="navigation.id"
             :class="[itemIndex > navigationRowCount || itemIndex >= navigationRowCount ? 'otherItem' : '',
             itemIndex % navigationRowCount == 0 ? 'row-first': '']">
          <div class="navigationImg">
            <a :href="navigation.targetUrl" target="_blank">
              <van-image width="100%" height="100%" fit="contain" lazy-load
                         :src="toMedia(navigation.imgUrl)"></van-image>
            </a>
          </div>
          <div class="navigationTitle pc-item-title">{{ navigation.title }}</div>
          <div class="navigationSubTitle pc-item-sub-title">{{ navigation.introduction }}</div>
        </div>
      </div>
    </div>
    <div v-show="showToTop == true" class="to-top" ref="toTop" @click="toTop">
      <img src="../img/to_top.svg" height="52" width="52"/>
    </div>
    <footer class="footer pc-footer">
      {{ footerInfo.yearContent }} ·
      <a :href="footerInfo.icpNumberTargetUrl" target="_blank">
        {{ footerInfo.icpNumber }}
      </a> ·
      <a :href="footerInfo.policeNumberTargetUrl" target="_blank">
        {{ footerInfo.policeNumber }}
      </a> ·
      <a :href="footerInfo.aboutUsTargetUrl" target="_blank">{{ footerInfo.aboutUs }}</a>
    </footer>
    <!--    <div class="navigationType">-->
    <!--      <van-search v-model="value" placeholder="请输入搜索关键词" />-->
    <!--      <van-tabs v-model="selectIndex" style="clear: both" scrollspy>-->
    <!--        <van-tab :title="navigationType.title" v-for="(navigationType, index) in navigationTypeList" :key="index"-->
    <!--                 :name="navigationType.id" :url="'#'+navigationType.id">-->
    <!--          <div class="navigationTypeTitle">{{ navigationType.title }}</div>-->
    <!--            <div class="navigationList">-->
    <!--              <div class="navigationItem" v-for="navigation in navigationData[navigationType.id].navigationList" :key="navigation.id">-->
    <!--                <div class="navigationImg">-->
    <!--                  <van-image width="100%" height="100%" fit="contain" :src="toMedia(navigation.imgUrl)"></van-image>-->
    <!--                </div>-->
    <!--                <div class="navigationTitle">{{ navigation.title }}</div>-->
    <!--                <div class="navigationTitle">{{ navigation.introduction }}</div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--        </van-tab>-->
    <!--      </van-tabs>-->
    <!--    </div>-->
  </div>
</template>

<script>
// @ is an alias to /src
import {bannerClick, getBanner} from "@/api/banner";
import {getNavigaionType} from "@/api/navigation_type";
import '@vant/touch-emulator';
import {toMedia} from "@/utils/request";
import {getAllNavigation, getNavigation} from "@/api/navigation";
import {getFooter} from "@/api/footer";

export default {
  name: "Home",
  data() {
    return {
      bannerList: [],
      bannerIndex: 0,
      navigationData: {},
      navigationTypeList: [],
      selectIndex: 0,
      footerInfo: {},
      searchTitle: '',
      showSearch: false,
      showSearchPanel: false,
      searchNavigationList: undefined,
      swipeTimer: "",
      hidePanel: false,
      showToTop: false,
      navigationRowCount: 7,
      scrollLeft: 0
    };
  },
  watch: {
    bannerList: function () {
      this.$nextTick(() => {
        this.$refs.navigationType.$refs.wrap.onclick = (e) => {
          if (e.target.className === "van-tabs__wrap" || e.target.className === "van-tabs__wrap van-tabs__wrap--scrollable") {
            this.showSearch = true
            this.$nextTick(() => {
              this.$refs.search.querySelector('input').focus()
            })
            // window.location.href = "#navigationType"
          }
        }
        setTimeout(() => {
          this.bannerNext()
        }, 3000)
        // document.getElementsByTagName('html')[0].style.fontSize = '75px';
        window.addEventListener('scroll', this.windowScrollListener)
        window.addEventListener('resize', this.windowSizeListener)
      })
    },
  },
  created() {
    getBanner().then(res => {
      this.bannerList = res.data
    })
    getNavigaionType().then(res => {
      this.navigationTypeList = res.data
    })
    getFooter().then(res => {
      this.footerInfo = res.data
    })
    if (this.navigationData != null) {
      getAllNavigation().then(res => {
        this.navigationData = res.data
        this.navigationList = this.navigationData[this.navigationTypeList[0].id].navigationList
      })
    }
    this.windowSizeListener()
  },
  mounted() {
    this.scrollInit()
  },
  methods: {
    // 初始化与绑定监听事件方法
    scrollInit() {
      // 获取要绑定事件的元素
      const nav = this.$refs.navigationType.$el.children[0].children[0]
      // const nav = ''
      var flag; // 鼠标按下
      var downX; // 鼠标点击的x下标
      var scrollLeft; // 当前元素滚动条的偏移量
      nav.addEventListener("mousedown", function (event) {
        flag = true;
        downX = event.clientX; // 获取到点击的x下标
        scrollLeft = this.scrollLeft; // 获取当前元素滚动条的偏移量
      });
      nav.addEventListener("mousemove", function (event) {
        if (flag) { // 判断是否是鼠标按下滚动元素区域
          var moveX = event.clientX; // 获取移动的x轴
          var scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
          this.scrollLeft = scrollLeft - scrollX // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
        }
      });
      // 鼠标抬起停止拖动
      nav.addEventListener("mouseup", function () {
        flag = false;
      });
      // 鼠标离开元素停止拖动
      nav.addEventListener("mouseleave", function () {
        flag = false;
      });
    },
    toMedia(url) {
      return toMedia(url)
    },
    onChange(index) {
      this.bannerIndex = index;
      clearTimeout(this.swipeTimer)
      this.swipeTimer = setTimeout(() => {
        this.$refs.banner.next()
      }, 3000)
    },
    bannerPrev() {
      this.$refs.banner.prev()
    },
    bannerNext() {
      this.$refs.banner.next()
    },
    bannerSwipe(index) {
      this.$refs.banner.swipeTo(index)
    },
    hideSearch() {
      if (this.searchTitle == '') {
        this.showSearch = false
        this.hidePanel = false
        this.searchNavigationList = undefined
        this.showSearchPanel = false
      }
    },
    onInput(value) {
      if (value != '') {
        this.showSearchPanel = true
        this.hidePanel = true
        getNavigation({title: value}).then(res => {
          this.searchNavigationList = res.data
        })
      }
    },
    bannerClick(banner) {
      bannerClick({id: banner.id})
      window._czc && window._czc.push(['_trackEvent', '轮播图', '点击', banner.title])
    },
    toTop() {
      window.scrollTo({
        top,
        behavior: "smooth"
      })
    },
    windowScrollListener() {
      var windowY = 999
      if (!this.showSearch) {
        windowY = this.$refs.navigationType.$refs.nav.getBoundingClientRect().y
      } else {
        windowY = this.$refs.search.getBoundingClientRect().y
      }
      if (windowY >= 0) {
        this.showToTop = false
      } else {
        this.showToTop = true
      }
    },
    windowSizeListener() {
      if (window.innerWidth > 1440) {
        this.navigationRowCount = 7
      } else if (window.innerWidth > 1280 && window.innerWidth <= 1440) {
        this.navigationRowCount = 5
      } else {
        this.navigationRowCount = 4
      }
    }
  }
};
</script>

<style scoped>
.row-first {
  position: relative;
}

.row-first::after {
  content: "";
  background-color: rgba(52, 52, 52, 0.05);
  width: 773%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.navigationTypeTitle {
  /*font-size: 27px;*/
  text-align: left;
  font-weight: 600;
  color: #343434;
  margin-left: 3.78%;
  padding-top: 1.57%;
  font-family: PingFangSC-Semibold, PingFang SC;
  /*border-top: rgba(52, 52, 52, 0.05) 2px solid;*/
}

/deep/ .van-field__body {
  height: 100%;
}

/deep/ .van-icon-clear:before {
  content: '';
  background-image: url("~@/img/search_clean.svg");
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  padding: 0 5px 12px 0;
}

/deep/ .van-field__control {
  font-size: 21px;
  /*height: 100%;*/
}

/deep/ .van-search__content {
  border: 3px solid rgba(52, 52, 52, 0.05);
  background-color: white;
  padding-left: 14px;
  height: 37%;
}

/deep/ .van-search {
  clear: both;
  height: 95px;
  margin-left: 3.78%;
  margin-right: 3.78%;
  padding: 0;
  display: flex;
}

.navigationItem {
  width: 12.43%;
  margin-right: 1.52%;
  /*padding-right: 0.8%;*/
  /*border-right: 18px solid rgba(0,0,0,0);*/
  /*border-bottom: rgba(52, 52, 52, 0.05) 2px solid;*/
  /*border-bottom: rgb(52, 52, 52) 2px solid;*/
}

.navigationList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 3.78%;
  /*margin-right: 3.75%;*/
}

/deep/ .van-tabs__wrap::after {
  content: "";
  width: 1.15%;
  aspect-ratio: 1/1;
  position: absolute;
  background-image: url("~@/img/search.svg");
  background-size: 100% 100%;
  right: 3.78%;
  /*top: 1.7%;*/
  top: 36px;
  cursor: pointer;
}

/deep/ .van-tabs__wrap::before {
  content: "";
  position: absolute;
  width: 2.5%;
  aspect-ratio: 1/2;
  /*background-color: #2c3e5080;*/
  background: linear-gradient(90deg, #FFFFFF00 0%, #FFFFFF 100%);
  right: 6%;
  z-index: 2;
  cursor: pointer;
  pointer-events: none;
}

/deep/ .van-tabs__wrap {
  width: 90%;
  margin-left: 3.78%;
  /*width: 81.86%;*/
}

/deep/ .van-tabs__wrap--scrollable .van-tab {
  /*padding-left: 0px;*/
  padding: 0 25px 0 0;
}

/deep/ .van-tabs--line .van-tabs__wrap {
  height: 95px;
}

/deep/ .van-tab {
  display: unset;
  text-align: left;
  font-size: 18px;
  line-height: 95px;
}

/deep/ .van-tabs__nav {
  /*display: block;*/
  text-align: left;
  height: 100%;
  width: 100%;
}

/deep/ .van-tab{
  flex: none !important;
}

.pc-type-item-title {
  font-size: 20px;
}

.bannerTitleListBottom {
  /*width: 21.57%;*/
  width: 100%;
  height: 54px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  /*background-color: #343434;*/
  position: absolute;
  top: 59.5%;
  pointer-events: none;
}

.bannerTitleList {
  width: 100%;
  aspect-ratio: 408/210;
  overflow: scroll;
}

.bannerPrev, .bannerNext {
  /*width: 4.87%;*/
  /*aspect-ratio: 18/20;*/
  width: 18px;
  height: 20px;
  background-size: 100% 100%;
  float: left;
  margin: 0;
}

.bannerPrev {
  margin-right: 20px;
}

.bannerIndicator {
  width: 100%;
  aspect-ratio: 411/20;
  display: flex;
  margin-top: 7%;
}

.fg {
  margin: 15px 0;
  height: 1px;
  background: rgba(52, 52, 52, 0.05);
}

.pc-indicator, .pc-item-title {
  font-size: 16px;
}

.indicator {
  width: 21.42%;
  aspect-ratio: 408/262;
  float: left;
  margin-left: 50px;
  position: relative;
}

/deep/ .van-swipe {
  width: 28.18%;
  aspect-ratio: 537/262;
  margin-left: 3.78%;
  float: left;
}

.bannerImg {
  width: 99.2%;
  aspect-ratio: 537/262;
  background-size: 100% 100%;
  /*margin-right: 5px;*/
}

.pc-title {
  text-align: left;
  font-size: 60px;
  margin: 60px 0 30px 3.78%;
  font-family: HarmonyOS_Sans_Black;
  color: #343434;
}

.to-top {
  position: fixed;
  bottom: 144px;
  right: 2px;
  cursor: pointer;
}

.navigationImg {
  overflow: hidden;
}

/deep/ .van-image:hover {
  transform: scale(1.1, 1.1)
}

.navigationImg, /deep/ .van-image {
  background-size: 100% 100%;
  width: 100%;
  aspect-ratio: 374/248;

  transition: transform 0.3s;
}

.pc-item-sub-title {
  font-size: 14px;
}

.pc-footer {
  font-size: 14px;
}

/deep/ .van-field__left-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
}

.no-data {
  font-size: 25px;
  color: rgba(52, 52, 52, 0.3);
  margin-top: 110px;
  margin-bottom: 200px;
}

.searchPanel {
  flex: 1;
}

.home {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/deep/ .show-search .van-tabs__wrap {
  display: none;
}

/deep/ .hide-panel .van-tabs__content {
  display: none;
}

.footer {
  text-align: left;
  margin-left: 3.78%;
  padding: 0.79% 0;
  font-weight: 400;
  color: rgba(52, 52, 52, 0.3);
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 0;
}

.navigationSubTitle {
  font-weight: 400;
  color: #343434;
  text-align: left;
  margin-bottom: 13.25%;
  /*max-width: 100%;*/
  /*text-overflow: ellipsis;*/
  /*white-space: nowrap;*/
  /*overflow: hidden;*/
  font-family: PingFangSC-Regular, PingFang SC;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.navigationTitle {
  font-weight: 600;
  color: #343434;
  text-align: left;
  margin-top: 6.68%;
  margin-bottom: 0.88%;
  /*max-width: 100%;*/
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
  /*white-space: nowrap;*/
  font-family: PingFangSC-Semibold, PingFang SC;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;

}

.otherItem {
  margin-top: 1.64%;
}

/deep/ .van-tabs__nav--line.van-tabs__nav--complete {
  padding: 0;
}

.bannerPrevSelect {
  background-image: url("~@/img/left_select.png");
}

.bannerPrevUnSelect {
  background-image: url("~@/img/left_unselect.png");
}

.bannerNextSelect {
  background-image: url("~@/img/right_select.png");
}

.bannerNextUnSelect {
  background-image: url("~@/img/right_unselect.png");
}


.bannerNum {
  float: left;
  margin-left: 30px;
  font-family: PingFangSC-Regular, PingFang SC;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

::-webkit-scrollbar {
  display: none;
}

/deep/ .van-sticky {
  /*float: left;*/
  /*width: 78%;*/
}

/deep/ .van-tab--active {
  font-weight: 600;
}

/deep/ .van-tabs__line {
  display: none;
}

.bannerTitleItem {
  text-align: left;
  /*font-size: 27px;*/

  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  cursor: pointer;
}


.notselected .bannerTitleItem:hover, /deep/ .van-tab__text:hover {
  color: rgba(52, 52, 52, 0.5);
}

/deep/ .van-tab--active .van-tab__text:hover {
  color: rgb(52, 52, 52);
}


.unClick {
  opacity: 0.5;
}

.selected {
  color: #343434;
  font-weight: 600;
  font-family: PingFangSC-Semibold, PingFang SC;
}

.notselected {
  color: rgba(52, 52, 52, 0.2);
  font-weight: 500;
  font-family: PingFangSC-Medium, PingFang SC;
}

.bannerTitle {
  display: none;
}

@media screen and (max-width: 1440px) {

  .to-top {
    right: 20px;
  }

  .navigationTypeTitle {
    margin-left: 6.25%;
    padding-top: 2.11%;
  }

  .footer {
    margin-left: 6.25%;
    padding: 1.05% 0;
  }

  .row-first::after {
    width: 553%;
  }

  .otherItem {
    margin-top: 2.25%;
  }

  /deep/ .van-tabs__wrap::after {
    width: 1.54%;
    right: 6.25%;
  }

  /deep/ .van-tabs__wrap::before {
    width: 3.4%;
    aspect-ratio: 1/2;
    right: 8.2%;
  }

  /deep/ .van-search {
    margin: 0 6.25%;
  }

  .navigationItem {
    width: 16.84%;
    margin-right: 2.25%;
  }

  /deep/ .van-tabs__wrap {
    width: 85%;
  }

  /deep/ .van-tabs__wrap, .navigationList {
    margin-left: 6.25%;
  }

  .indicator {
    width: 28.49%;
  }

  /deep/ .van-swipe {
    width: 37.54%;
    margin-left: 6.25%;
  }

  .pc-title {
    margin-left: 6.25%;
  }
}

@media screen and (max-width: 1280px) {
  .to-top {
    right: 68px;
  }

  .navigationTypeTitle {
    margin-left: 10.86%;
    padding-top: 2.25%;
  }

  .footer {
    margin-left: 10.86%;
    padding: 1.19% 0;
  }

  .row-first::after {
    width: 440%
  }

  .otherItem {
    margin-top: 2.66%;
  }

  /deep/ .van-tabs__wrap::after {
    width: 1.74%;
    right: 10.86%;
  }

  /deep/ .van-search {
    margin: 0 10.86%
  }


  .navigationItem {
    width: 19.96%;
    margin-right: 2.66%;
  }

  /deep/ .van-tabs__wrap::before {
    content: "";
    position: absolute;
    width: 3.8%;
    aspect-ratio: 1/2;
    right: 13%;
  }

  /deep/ .van-tabs__wrap {
    width: 75%;
  }

  /deep/ .van-tabs__wrap, .navigationList {
    margin-left: 10.86%;
  }

  .indicator {
    width: 32.09%;
  }

  /deep/ .van-swipe {
    width: 42.29%;
    margin-left: 10.86%;
  }

  .pc-title {
    margin-left: 10.86%;
  }
}
</style>
