import request from '@/utils/request'

// 获取列表
export function getBanner () {
    return request({
        url: 'api/banner',
        method: 'get'
    })
}

export function bannerClick (query) {
    return request({
        url: 'api/banner',
        method: 'post',
        params: query
    })
}
