import request from '@/utils/request'

export function getNavigation (query) {
    return request({
        url: `api/navigation`,
        method: 'get',
        params: query
    })
}

export function getAllNavigation () {
    return request({
        url: `api/navigation/get_all`,
        method: 'get'
    })
}
